module.exports = {
    development : false,
    wrapper: {
      url: "https://hubdriveonline.com:9080/"
  },
  api:{
    url: "https://hubdriveonline.com:9080/"
  },
  ui:{
    url: "https://hubdriveonline.com"
  },
  desktop: {
  settings: {
    font: 'Roboto',
          theme: "HUBTheme"
  }
  },
   keycloakLogin:true,
   keycloak: {
        keycloakRedirectUrl:"https://hubdriveonline.com/",
        keycloakUrl: "https://secureox.eoxvantage.com/",
        keycloakRealm: "HUBDriveOnline",
        keycloakClientId: "hubdriveonline",
  },
}
