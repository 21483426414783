import { Login as defaultLogin } from "../../osjs-client/index.js";
import "../assets/scss/login.scss";
import React from "react";
import ReactDOM from "react-dom";
import { ReactKeycloakProvider } from "@react-keycloak/web";
import Keycloak from "keycloak-js";
import oxLogo from "../assets/images/hubdrive.png";
import ForgotPassword from "./ForgotPassword.js";
import ResetPasswordPage from "./ResetPasswordPage.js";
import Slider from "./Slider.js";
export default class LoginContainer extends defaultLogin {
	constructor(core, options = {}) {
		super(core, options || {});
		this.core = core;
		this.baseurl = this.core.config("ui.url");
		this.keycloakUrl = this.core.config("keycloak.keycloakUrl");
		this.realm = this.core.config("keycloak.keycloakRealm");
		this.clientId = this.core.config("keycloak.keycloakClientId");
		this.keycloak = new Keycloak({
			url: this.keycloakUrl + "auth",
			realm: this.realm,
			clientId: this.clientId,
		});
		this.state= {
			resetPasswordToken: "",

		}
		this.onLoad();
	}

	// componentDidMount() {
	// 	if (this.core.config("keycloakLogin")){
	// 		var queryObj = window.location.search.substr(1);
	// 		queryObj = queryObj.split("&").reduce(function (prev, curr) {
	// 			var p = curr.split("=");
	// 			prev[decodeURIComponent(p[0])] = decodeURIComponent(p[1]);
	// 			return prev;
	// 		}, {});
	// 		queryObj.resetpassword
	// 			? this.setState({
	// 					// showPage: "resetpassword",
	// 					resetPasswordToken: queryObj.resetpassword,
	// 			})
	// 			: null;
	// 		document
	// 			.getElementById("ox-login-form")
	// 			.addEventListener("loginMessage", this.errorMessage, false);
	// 	}
	// }
	
	async onLoad() {
		if (this.core.config("keycloakLogin")) {
			const redirect =
				window.location.href.split(/#state|&code|&session_state/).length === 4;
			if (window.location.href.includes("resetpassword")){
				var queryObj = window.location.search.substr(1);
				queryObj = queryObj.split("&").reduce(function (prev, curr) {
					var p = curr.split("=");
					prev[decodeURIComponent(p[0])] = decodeURIComponent(p[1]);
					return prev;
				}, {});
				queryObj.resetpassword
					? this.state.resetPasswordToken = queryObj.resetpassword
					: null;
				return;
			}else if(!redirect){
				return;
			}
			const formData = new FormData();
			formData.append(
				"client_id",
				this.core.config("keycloak.keycloakClientId")
			);
			formData.append("grant_type", "authorization_code");
			formData.append(
				"redirect_uri",
				this.core.config("keycloak.keycloakRedirectUrl")
			);
			formData.append("code", window.location.href.split("&code=")[1]);
			const response = await (
				await fetch(
					this.core.config("keycloak.keycloakUrl") +
						"auth/realms/" +
						this.core.config("keycloak.keycloakRealm") +
						"/protocol/openid-connect/token",
					{
						body: new URLSearchParams(formData),
						method: "post",
						headers: { "Content-Type": "application/x-www-form-urlencoded" },
					}
				)
			).json();
			const formData1 = new FormData();
			formData1.append("access_token", response.access_token);
			if (response.access_token) {
				let response1 = await (
					await fetch(
						this.core.config("wrapper.url") + "auth/external/keycloak",
						{
							method: "post",
							headers: { "Content-Type": "application/x-www-form-urlencoded" },
							body: new URLSearchParams(formData1),
						}
					)
				).json();
				console.log("Inside the KeyCloak", response1);
				let res = [
					{ result: response1.data, ref_token: response.refresh_token },
				];
				this.emit("login:post", res);

				window.location.replace(this.baseurl);
			}
		}
	}

	render() {
		var node = document.createElement("div");
		node.className = "reactLoginPage";
		document.body.appendChild(node);

		// let authToken = this.core.make("oxzion/profile").getAuth();
		let location = window.location.href;

		if (location.includes("#forgotpassword")) {
			ReactDOM.render(
				<ForgotPassword
					core={this.core}
					showLoginPage={() => window.location.replace(this.baseurl)}
				/>,
				document.getElementsByClassName("reactLoginPage")[0]
			);
		}
		else if (this.core.config("keycloakLogin") && location.includes("resetpassword")){
			ReactDOM.render(
				 		<ResetPasswordPage
				 			core={this.core}
				 			resetPasswordToken={this.state.resetPasswordToken}
				 			showLoginPage={() => window.location.replace(this.baseurl)}
				 		/>,
				 		document.getElementsByClassName("reactLoginPage")[0]
				 	);
		}
		else if (this.core.config("keycloakLogin")) {
			ReactDOM.render(
				KcLogin(this.keycloak),
				document.getElementsByClassName("reactLoginPage")[0]
			);
		} else {
			const b = ReactDOM.render(
				<Login
					core={this.core}
					triggerSubmit={(values) => {
						this.emit("login:post", values);
					}}
				/>,
				document.getElementsByClassName("reactLoginPage")[0]
			);
			this.on("login:stop", () => {
				let AuthToken = this.core.make("oxzion/profile").getAuth();
				if (AuthToken) {
					ReactDOM.unmountComponentAtNode(
						document.getElementsByClassName("reactLoginPage")[0]
					);
				}
			});
			this.on("login:error", (err) => {
				let ev = new CustomEvent("loginMessage", {
					detail: err,
					bubbles: true,
				});
				document.getElementById("ox-login-form").dispatchEvent(ev);
			});
		}
	}
}
const KcLogin = (keycloak) => {
	const redirect =
		window.location.href.split(/#state|&code|&session_state/).length === 4;
	return !redirect ? (
		<ReactKeycloakProvider
			authClient={keycloak}
			initOptions={{ onLoad: "login-required", checkLoginIframe: false }} // onLoad: "login-required"
			onEvent={(event, error) => {
				console.log("onKeycloakEvent", event, error);
			}}
		></ReactKeycloakProvider>
	) : null;
};

//OSJS-LOGIN
class Login extends React.Component {
	constructor(props) {
		super(props);
		this.core = this.props.core;
		this.state = {
			showPage: "login",
			error: false,
			username: "",
			password: "",
			resetPasswordToken: "",
		};
		this.handleChange = this.handleChange.bind(this);
		this.loginAction = this.loginAction.bind(this);
		this.errorMessage = this.errorMessage.bind(this);
	}

	componentDidMount() {
		var queryObj = window.location.search.substr(1);
		queryObj = queryObj.split("&").reduce(function (prev, curr) {
			var p = curr.split("=");
			prev[decodeURIComponent(p[0])] = decodeURIComponent(p[1]);
			return prev;
		}, {});
		queryObj.resetpassword
			? this.setState({
					showPage: "resetpassword",
					resetPasswordToken: queryObj.resetpassword,
			  })
			: null;
		document
			.getElementById("ox-login-form")
			.addEventListener("loginMessage", this.errorMessage, false);
	}

	errorMessage(e) {
		this.setState({ error: e.detail });
	}

	handleChange(e) {
		let target = e.target;
		this.setState({ [target.name]: target.value });
	}

	loginAction(e) {
		e.preventDefault();
		var loginDetails = {
			username: this.state.username,
			password: this.state.password,
		};
		this.props.triggerSubmit(loginDetails);
	}

	render() {
		if (this.state.showPage == "resetpassword") {
			return (
				<ResetPasswordPage
					core={this.core}
					resetPasswordToken={this.state.resetPasswordToken}
					showLoginPage={() => this.setState({ showPage: "login" })}
				/>
			);
		} else if (this.state.showPage == "forgotpassword") {
			return (
				<ForgotPassword
					core={this.core}
					showLoginPage={() => this.setState({ showPage: "login" })}
				/>
			);
		} else {
			return (
				<main id="login-container " className="loginContainer row lighten-3 ">
					<div
						className="loginSlider col-8"
						style={{
							display: "flex",
							flexDirection: "column",
							justifyContent: "center",
						}}
					>
						<Slider core={this.core} />
					</div>
					<div id="ox-login-form" className="col-4">
						<div
							className="form-wrapper__inner"
							id="loginPage"
							style={{ display: "block" }}
						>
							<form
								className="ox-form "
								method="post"
								onSubmit={this.loginAction}
							>
								<div id="ox-img" className="ox-imgDiv">
									<img id="ox-logo" className="ox-img" src={oxLogo} />
								</div>
								<div className="floating-label">
									<input
										type="text"
										name="username"
										className="validate"
										id="username"
										placeholder="Username"
										value={this.state.username}
										onChange={this.handleChange}
									/>
									<label htmlFor="username">Username</label>
								</div>
								<div className="floating-label">
									<input
										type="password"
										name="password"
										className="validate"
										id="password"
										placeholder="Password"
										value={this.state.password}
										onChange={this.handleChange}
									/>
									<label htmlFor="password">Password</label>
								</div>
								<div className="form-signin__footer">
									<button
										type="submit"
										value="login"
										className="btn waves-effect waves-light"
									>
										Login
									</button>
									<a
										href="#"
										onClick={() =>
											this.setState({ showPage: "forgotpassword" })
										}
									>
										Forgot your password?
									</a>
								</div>
							</form>
							<div
								className="osjs-login-error"
								style={{ display: this.state.error ? "block" : "none" }}
							>
								<span>
									The username and/or password is incorrect! Please try again.
								</span>
							</div>
						</div>
						<footer className="footer-links">
							<a href="https://www.eoxvantage.com">About Us</a> &nbsp;&nbsp; |
							&nbsp;&nbsp;
							<a href="./privacy-policy" target="_blank">
								Privacy Policy
							</a>
							<div className="coppyRight">
								Copyright © 2004-2021 EOX Vantage. All rights reserved.
							</div>
						</footer>
					</div>
				</main>
			);
		}
	}
}
